/* eslint-disable react/prop-types */
import * as React from 'react';
import Timer from 'react-compound-timer';

import { padStart } from 'lodash';
import styled from 'styled-components';

import { usePromotionData } from './FixedTopBarScholarship/usePromotionData';
import IconColon from './icons/IconColon';
import ScholarshipBannerCarousel from './ScholarshipBannerCarousel';
import { colorsV2 } from '../../style/colors-v2';
import { fromScreen } from 'utils/media-query/responsive.util';
import { IconLearningReading, IconGroup } from 'components/Icon/SVGIcons';
import { Typography } from 'components/DesignSystemV2/Typography';
import { MONTH_NAME_FORMAT2 } from 'constant';
import moment from 'moment';

const formatDuration = durationValue => padStart(durationValue, 2, '0');

const Main = styled.div`
  background-color: ${colorsV2.green100};
  border-radius: 8px;
  display: grid;
  align-items: center;
  justify-content: space-between;

  overflow: hidden;
  margin: 0 auto;

  padding: 24px 16px;
  width: 100%;

  .section-right {
    display: none;
  }
  .title {
    letter-spacing: 0px;
    text-align: left;
    margin-bottom: 16px;
  }
  .sub-text {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;

    font-style: normal;
    letter-spacing: 0px;
    text-align: left;
    color: #d7e7dd;
    margin-bottom: 12px;
    .highlight {
      border-bottom: 2px solid #ff991f;
      font-weight: 600;
    }
    display: grid;
    grid-template-columns: minmax(0, max-content) 1fr;
    gap: 8px;
    align-items: center;
    svg {
      font-size: 24px;
    }
  }
  .countdown-wrapper {
    justify-content: space-between;
  }
  .countdown-number {
    .countdown-number-box {
      padding: 8px;
      font-size: 41px;
      line-height: 41px;

      min-width: 72px;
      max-width: 72px;
      width: 72px;
      overflow: hidden;
      border-radius: 6.85px;
      border: 2px solid #2e794c;

      font-weight: 400;
      letter-spacing: 0px;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #ffffff;
    }
    .countdown-number-unit {
      font-size: 11px;
      line-height: 18px;
      text-align: center;
      font-style: normal;
      font-weight: 400;

      letter-spacing: 0px;
      color: #d7e7dd;
    }
  }
  .countdown-separator {
    margin: 0 0px 8px 0px;
  }
  .row-center {
    display: flex;
    align-items: center;
  }

  ${fromScreen(776)} {
    grid-template-columns: 372px 1fr;
    gap: 30px;
    .section-right {
      display: block;
    }
  }

  ${fromScreen(1144)} {
    grid-template-columns: 520px 1fr;
    padding: 50px 60px;

    .sub-text {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      &.notice {
        margin-bottom: 36px;
      }
    }

    .countdown-number {
      .countdown-number-box {
        min-width: 74px;
        max-width: 74px;
        width: 74px;
        padding: 10px;
        font-size: 40px;
        line-height: 48px;
      }
      .countdown-number-unit {
        font-size: 12px;
        line-height: 20px;
      }
    }
    .countdown-separator {
      margin: 0 12px 12px 12px;
    }
    .title {
      margin-bottom: 32px;
    }
  }
`;

const PromotionList = styled.div``;

const ScholarshipBanner = ({ data }) => {
  const {
    displayName,
    remainSlot: remainQuantity,
    value,
    endDate,
    interestQuantity
  } = data[0] || {};

  const initialTime = Math.max(
    new Date(endDate).getTime() - new Date().getTime(),
    0
  );

  return (
    <Main className="scholarship-banner">
      <div className="section-left info">
        <Typography
          v3
          variant="bold/24-32"
          tabletVariant="bold/32-40"
          desktopVariant="bold/40-50"
          color={colorsV2.white100}
          className="title"
          as="h1"
        >
          {displayName || 'Ưu đãi khoá Offline'}
        </Typography>

        {data.length > 1 ? (
          <PromotionList>
            {data.map(
              ({ remainSlot: remainQuantity, value, courseName }, index) => (
                <div key={index} className="sub-text">
                  <IconLearningReading />
                  <div>
                    {'Khóa '}
                    <span className="highlight">{courseName}</span> còn{' '}
                    <span className="highlight">{remainQuantity || 0}</span>{' '}
                    suất học bổng lên đến{' '}
                    <span className="highlight">
                      {value || '12.000.000 VND'}
                    </span>
                  </div>
                </div>
              )
            )}
          </PromotionList>
        ) : (
          <div className="sub-text">
            <IconLearningReading />
            <div>
              {'Khóa '}
              <span className="highlight">IELTS</span> còn{' '}
              <span className="highlight">{remainQuantity || 2987}</span> suất
              học bổng lên đến{' '}
              <span className="highlight">{value || '12.000.000 VND'}</span>
            </div>
          </div>
        )}

        <div className="sub-text">
          <IconGroup />
          <div>
            Có <span className="highlight">{interestQuantity || 2087}</span>{' '}
            người đang quan tâm khóa học ở DOL
          </div>
        </div>

        <div className="sub-text notice">
          *Đăng ký ngay trong 48h để được nhận ưu đãi khủng nhất
        </div>

        <div className="sub-text">Kết thúc sau:</div>
        <Timer initialTime={initialTime || 0} direction="backward">
          {() => (
            <div className="row-center countdown-wrapper">
              <div className="countdown-number">
                <div className="countdown-number-box">
                  <Timer.Days formatValue={formatDuration} />
                </div>
                <div className="countdown-number-unit">Ngày</div>
              </div>
              <div className="countdown-separator">
                <IconColon />
              </div>
              <div className="countdown-number">
                <div className="countdown-number-box">
                  <Timer.Hours formatValue={formatDuration} />
                </div>
                <div className="countdown-number-unit">Giờ</div>
              </div>
              <div className="countdown-separator">
                <IconColon />
              </div>
              <div className="countdown-number">
                <div className="countdown-number-box">
                  <Timer.Minutes formatValue={formatDuration} />
                </div>
                <div className="countdown-number-unit">Phút</div>
              </div>
              <div className="countdown-separator">
                <IconColon />
              </div>
              <div className="countdown-number">
                <div className="countdown-number-box">
                  <Timer.Seconds formatValue={formatDuration} />
                </div>
                <div className="countdown-number-unit">Giây</div>
              </div>
            </div>
          )}
        </Timer>
      </div>

      <div className="section-right">
        <ScholarshipBannerCarousel />
      </div>
    </Main>
  );
};

const ScholarshipBannerMain = () => {
  const promotionScholarships = usePromotionData({ filterBy: 'primary' });
  return <ScholarshipBanner data={promotionScholarships} />;
};

export default ScholarshipBannerMain;
